import { Injectable } from '@angular/core';
import { EventTicket } from '@models/events/event-ticket.model';
import { TicketVoucherCodeResponse } from '@models/tickets/dto/ticket-voucher-code.response';
import { TicketLayout } from '@models/tickets/ticket-layout.model';
import { TicketService } from '@services/tickets/ticket.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TicketsStore {
  private _eventTicket: BehaviorSubject<EventTicket | undefined> =
    new BehaviorSubject<EventTicket | undefined>(undefined);
  private _eventTickets: BehaviorSubject<EventTicket[] | undefined> =
    new BehaviorSubject<EventTicket[] | undefined>(undefined);

  private _ticketCodeVoucher: BehaviorSubject<
    TicketVoucherCodeResponse | undefined
  > = new BehaviorSubject<TicketVoucherCodeResponse | undefined>(undefined);

  private _ticketCodeVouchers: BehaviorSubject<
    TicketVoucherCodeResponse[] | undefined
  > = new BehaviorSubject<TicketVoucherCodeResponse[] | undefined>(undefined);

  private _ticketLayout: BehaviorSubject<TicketLayout | undefined> =
    new BehaviorSubject<TicketLayout | undefined>(undefined);
  private _ticketLayouts: BehaviorSubject<TicketLayout[] | undefined> =
    new BehaviorSubject<TicketLayout[] | undefined>(undefined);

  private _errorMessage: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);

  public readonly eventTickets: Observable<EventTicket[] | undefined> =
    this._eventTickets.asObservable();

  public readonly eventTicket: Observable<EventTicket | undefined> =
    this._eventTicket.asObservable();

  public readonly ticketCodeVoucher: Observable<
    TicketVoucherCodeResponse | undefined
  > = this._ticketCodeVoucher.asObservable();

  public readonly ticketCodeVouchers: Observable<
    TicketVoucherCodeResponse[] | undefined
  > = this._ticketCodeVouchers.asObservable();

  public readonly ticketLayouts: Observable<TicketLayout[] | undefined> =
    this._ticketLayouts.asObservable();
  public readonly ticketLayout: Observable<TicketLayout | undefined> =
    this._ticketLayout.asObservable();

  public readonly errorMessage: Observable<string | undefined> =
    this._errorMessage.asObservable();

  constructor(private ticketService: TicketService) {}

  getEventTickets(eventId: number) {
    this.ticketService.getTickets(eventId).subscribe({
      next: (eventTickets) => {
        this._eventTickets.next(eventTickets);
      },
      error: (error) => {
        if (error?.error) {
          this._errorMessage.next(error.error);
        }
      },
    });
  }

  getTicketCodeVouchers(eventId: number) {
    this.ticketService.getVoucherCodes(eventId).subscribe({
      next: (ticketCodeVouchers) => {
        this._ticketCodeVouchers.next(ticketCodeVouchers);
      },
      error: (error) => {
        if (error?.error) {
          this._errorMessage.next(error.error);
        }
      },
    });
  }

  getTicketLayouts(params: { eventId?: number; spaceId?: number }) {
    this.ticketService
      .getTicketLayouts(params.eventId, params.spaceId)
      .subscribe({
        next: (ticketLayouts) => {
          this._ticketLayouts.next(ticketLayouts);
        },
        error: (error) => {
          if (error?.error) {
            this._errorMessage.next(error.error);
          }
        },
      });
  }

  refreshEventTickets(eventId: number) {
    this.getEventTickets(eventId);
  }

  refreshTicketCodeVouchers(eventId: number) {
    this.getTicketCodeVouchers(eventId);
  }

  refreshTicketLayouts(params: { eventId?: number; spaceId?: number }) {
    this.getTicketLayouts(params);
  }

  setEventTickets(eventTickets: EventTicket[]) {
    this._eventTickets.next(eventTickets);
  }

  setEventTicket(eventTicket?: EventTicket) {
    this._eventTicket.next(eventTicket);
  }

  setTicketCodeVoucher(ticketCodeVoucher?: TicketVoucherCodeResponse) {
    this._ticketCodeVoucher.next(ticketCodeVoucher);
  }

  setTicketCodeVouchers(ticketCodeVouchers: TicketVoucherCodeResponse[]) {
    this._ticketCodeVouchers.next(ticketCodeVouchers);
  }

  setTicketLayouts(ticketLayouts: TicketLayout[]) {
    this._ticketLayouts.next(ticketLayouts);
  }

  setTicketLayout(ticketLayout?: TicketLayout) {
    this._ticketLayout.next(ticketLayout);
  }
}
